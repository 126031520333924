import { useCallback, useEffect, useMemo } from 'react';

import { AuthService } from '_common/services';
import { useSelector, useDispatch, useEffectOnUpdate } from '_common/hooks';
import { useFilterSelector } from '_common/components/Filters/FilterSlice';

import { EmptyFilteredState } from '_common/components';
import { listObjects } from '_common/components/Table/TableSlice';

import { LOADING_STATE } from 'Settings/pages/TenantSettingsPage/Users/constants';

import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import { useIntl } from 'react-intl';
import Cell from '_common/components/Table2/Cells';
import dayjs from 'dayjs';
import { ThunksTable } from '_common/components/Table2';
import AdministratorCell from '../Cells/AdministratorCell';
import UserSuffixCell from '../Cells/UserSuffixCell/UserSuffixCell';
import style from './List.module.scss';
import EmailAddressCell from '../Cells/EmailAddressCell';
import NameCell from '../Cells/NameCell';

const IDENTITY: Table.Identity = 'userManagement/users';

const List = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { selectFilterParams } = useFilterSelector();

  const searchParams = useSelector((state) => state.userManagement.filters);
  const filterParams = useSelector((state) =>
    selectFilterParams(state, state.filters.tenantSettings_users),
  );
  const list = useSelector((state) => state.table.identity['userManagement/users'].list);
  const users = useSelector((state) => state.userManagement.users);
  const contentLoading = useSelector((state) => state.table.contentLoading);

  //#region Table object listing handlers
  const fetchObjects = useCallback(
    async (parameters: any) => {
      const response = await new AuthService().getUsersList({
        ...parameters,
        filter_fields: [...searchParams.filter_fields, ...filterParams.filter_fields],
        filter_values: [...searchParams.filter_values, ...filterParams.filter_values],
      });

      return {
        ...response,
        data: {
          //@ts-expect-error Missing endpoint type "/api/authority/user/list"
          nodes: response.data.items.map((item) => {
            return { ...item, id: `${item.id}` };
          }),
          dataStorage: 'tenantUsers',
        },
      };
    },
    [searchParams, filterParams],
  );

  useEffect(() => {
    dispatch(
      listObjects({
        identity: IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  useEffectOnUpdate(() => {
    dispatch(
      listObjects({
        identity: IDENTITY,
        fetch: fetchObjects,
        cause: 'FILTER',
        request: {
          offset: 0,
          filter_fields: [...searchParams.filter_fields, ...filterParams.filter_fields],
          filter_values: [...searchParams.filter_values, ...filterParams.filter_values],
        },
      }),
    );
  }, [fetchObjects, searchParams, filterParams]);
  //#endregion

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'email',
        header: intl.formatMessage({ id: 'EMAIL_ADDRESS' }),
        orderable: true,
        width: 368,
        flex: true,
      },
      {
        id: 'first_name',
        header: intl.formatMessage({ id: 'FIRST_NAME' }),
        orderable: true,
        width: 120,
      },
      {
        id: 'last_name',
        header: intl.formatMessage({ id: 'LAST_NAME' }),
        orderable: true,
        width: 120,
      },
      {
        id: 'date_joined',
        header: intl.formatMessage({ id: 'storage.browserHeader.dateModified' }),
        orderable: true,
        width: 160,
      },
      {
        id: 'admin',
        header: intl.formatMessage({ id: 'ADMINISTRATOR' }),
        width: 104,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list.map((userId) => {
      const user = users[userId];

      return {
        id: user.id,
        email: (
          <Cell testId={`${userId}-email-column`} ellipsis>
            <EmailAddressCell user={user} />
          </Cell>
        ),
        first_name: (
          <Cell testId={`${userId}-first-name-column`}>
            <NameCell firstName user={user} />
          </Cell>
        ),
        last_name: (
          <Cell testId={`${userId}-last-name-column`}>
            <NameCell user={user} />
          </Cell>
        ),
        date_joined: (
          <Cell testId={`${userId}-date-modified-column`}>
            <div
              data-testid="item-dateJoined"
              className={`${!user.is_active && style.deactivated}`}
            >
              {dayjs(user.date_joined).format('DD/MM/YYYY hh:mm A')}
            </div>
          </Cell>
        ),
        admin: (
          <Cell testId={`${userId}-admin-column`}>
            <div className={`${style.admin}`}>
              <AdministratorCell user={user} />
            </div>
          </Cell>
        ),
        suffix: (
          <Cell testId={`${userId}-suffix-column`}>
            <UserSuffixCell user={user} />
          </Cell>
        ),
      };
    });
  }, [users]);

  if (list.length === 0 && filterParams.filter_fields.length > 0) {
    return (
      <div className={`${style.center} ${style.empty}`}>
        <EmptyFilteredState identity="tenantSettings_users" />
      </div>
    );
  }

  return (
    <div className={style.list}>
      <ThunksTable
        identity={IDENTITY}
        columns={columns}
        value={value}
        fetchObjects={fetchObjects}
        selectable={false}
        renderFooter={() => null}
        loadingLabel={intl.formatMessage({
          id: contentLoading ? LOADING_STATE[contentLoading] : LOADING_STATE.INITIAL,
        })}
        testId="users"
      />
    </div>
  );
};

export default List;
